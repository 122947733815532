<script setup>
import { vAutoAnimate } from '@formkit/auto-animate';
import minus from '@i/minus-white.svg';
import plus from '@i/plus-pink.svg';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

let faqs = [
    {
        q: t('What is Flex@ble?'),
        a: t(
            'At Flex@ble, you decide where and when you work! Moreover, you retain the advantages of working in salaried employment and benefit from the freedom that flexible working offers! Of course, it is also possible to work as a flexer (from 18 years).'
        ),
    },
    {
        q: t('What is the difference between Flex@ble and traditional employment agencies?'),
        a: t(
            'Flex@ble simply offers a platform where employees & flexers and clients can meet. We support you as a (temp) employee or flexer and the client only as a facilitator and send you the contracts/agreements if necessary.<br /><br />In the case of a flexer, we will get you in touch with the client with whom you can enter into an agreement.'
        ),
    },
    {
        q: t('Who are the clients?'),
        a: t('Flex@ble focuses in a broad sense on the retail sector, with the main focus on supermarkets.'),
    },
    {
        q: t('In which regions is Flex@ble active?'),
        a: t(
            'Flex@ble operates nationwide: from Groningen to Maastricht and from Amsterdam to Breda. On the platform you will find shifts throughout the Netherlands.'
        ),
    },
    {
        q: t('What kind of work can I do via Flex@ble?'),
        a: t(
            "You can find work in various positions in retail, specifically in supermarkets, via Flex@ble. Whether you like working in a bakery or butcher's shop or whether you prefer to work at the cash desk, anything is possible!"
        ),
    },
    {
        q: t('Do I need work experience to work for Flex@ble?'),
        a: t(
            'Anyone can work via Flex@ble! You do not need work experience in a specific sector. Flex@ble offers various e-learnings so that you can develop on the platform.'
        ),
    },
    {
        q: t('How old do you have to be to work for Flex@ble?'),
        a: t(
            'At Flex@ble, you can register from the age of 16. For certain shifts, you must be slightly older, but this is indicated in the description.<br /><br />As a freelancer, you can register on the platform from the age of 18.'
        ),
    },
    {
        q: t('How (fast) will I be paid? / When will I get paid?'),
        a: t(
            'At Flex@ble, every Friday is PayDay! Every Friday, your hours worked (from the Thursday of the previous week until last Wednesday) will be paid.'
        ),
    },
    {
        q: t('Can I work as an employee or as a flexer?'),
        a: t(
            'Good news! You can choose how you want to work while signing up.<br /><br />There are various shifts that can be fulfilled both as a temp and as a flexer.<br /><br />If you go for a managerial position (often long-term), however, these can only be carried out as a flexer. As a flexer, you can register on Flex@ble from the age of 18.'
        ),
    },
    {
        q: t('How does the € 1.000,- Bitcoin bonus work?'),
        a: `What should you do to the claim this bonus? Nothing, it's very simple. Among all registrations until 31 October, we will award one time € 1.000,- in Bitcoin! The prize winner will be notified after a draw in mid-November<br><br><strong>Requirements</strong><br>- You need to register on the Flex@ble landing page before 31 October 2022 to be entitled to this one-time bonus;<br>    - It is not allowed to register with multiple email addresses, this will result in exclusion from participation;<br>    - It is not possible to receive the amount in euro;<br>    - The bonus can only be converted into euros after purchase on the platform of our partner Bitmymoney;<br>    - Bitmymoney will automatically create a wallet for you in which you will receive the bonus, you will be informed by mail after the draw;<br>    - Flex@ble cannot be held responsible for possible price changes;<br>    - The risk of investing in Bitcoin, and investing in general, is that you can lose your money.<br>`,
    },
    {
        q: t("How does the 'Bring a Friend' bonus work?"),
        a: t(
            "Earning money from your bed? That sounds too good to be true, but at Flex@ble it is possible with the unique 'Bring a Friend' bonus! Of course, you first have to register on Flex@ble.nl as a new Fl@xr. After we have verified your identity, you will have access when we are live to all services in your area. Claim your first shift and get to work! Once you are convinced that working via Flex@ble is really cool, you can have your friends sign up with your unique referral code, which can be found in your profile. For every hour that they work (for you), you will get paid € 0,50. Literally get rich while sleeping! This bonus is paid out monthly. Would you like to get this nice extra paid out in Bitcoin? You can!"
        ),
    },
    {
        q: t("What if I don't want a bonus in Bitcoin?"),
        a: t('No worries! If you do not want a bonus in Bitcoin, we will simply transfer it to your bank account!'),
    },
];

const current = ref(0);

const setFaq = (i) => {
    if (current.value == i) {
        current.value = null;
    } else {
        current.value = i;
    }
};
</script>
<template>
    <div class="container my-12 lg:my-20 max-w-300">
        <h2 class="mb-6 font-bold text-center text-white lg:mb-15 text-5 lg:text-12">
            {{ $t('FAQs') }}
        </h2>
        <div class="grid gap-6">
            <ul class="grid gap-6">
                <li class="accordion-item" v-for="(faq, i) in faqs" :key="i" v-auto-animate>
                    <button
                        :class="{ 'bg-pink text-white': current == i, 'bg-white text-pink': current != i }"
                        class="relative flex text-3.5 lg:text-6 items-center w-full font-bold rounded-full px-8 py-4 min-h-10 lg:min-h-15 leading-none text-left uppercase"
                        @click="setFaq(i)"
                    >
                        <span class="mr-12">
                            {{ $t(faq.q) }}
                        </span>

                        <div
                            class="absolute w-4 h-4 -translate-y-1/2 pointer-events-none lg:w-6 lg:h-6 right-7 top-1/2 flex-center"
                        >
                            <img
                                class="absolute"
                                :class="{ 'opacity-100': current != i, 'opacity-0': current == i }"
                                :src="plus"
                                alt="open"
                            />
                            <img
                                class="absolute"
                                :class="{ 'opacity-100': current == i, 'opacity-0': current != i }"
                                :src="minus"
                                alt="close"
                            />
                        </div>
                    </button>
                    <p
                        class="p-4 pb-0 font-medium lg:p-8 text-3.5 leading-loose lg:text-6 lg:leading-7"
                        v-if="i === current"
                        v-html="$t(faq.a)"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
